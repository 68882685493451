<template>
  <!-- 扫码核销组件 -->
  <div class="serve-cancel-btn" @click="getConfig">
    <slot></slot>
  </div>
</template>
<script>
import { getConfigs, writeOffsm } from "@/api/home";
import { hxNhCoupon } from "@/api/common";
import wx from "weixin-js-sdk";
export default {
  name: "serveCancelBtn",
  props: ["shopId"],
  components: {},
  data() {
    return {
      wxConfig: {},
    };
  },
  methods: {
    async getConfig() {
      try {
        const res = await getConfigs(location.href.split("#")[0]);
        let resp = res.result
        if (resp.code == 200) {
          this.wxConfig = resp.data;
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: this.wxConfig.appid, // 必填，公众号的唯一标识
            timestamp: this.wxConfig.timestamp, // 必填，生成签名的时间戳
            nonceStr: this.wxConfig.nonceStr, // 必填，生成签名的随机串
            signature: this.wxConfig.signature, // 必填，签名
            jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表
          });
          wx.ready(() => {
            console.log("执行了wx.ready()");
            this.goRichScan();
          });
          wx.error(res => {
            console.log(res);
          });
        }
      } catch (error) {
        this.$toast.fail(error);
      }
    },
    async goRichScan() {
      wx.scanQRCode({
        // 微信扫一扫接口
        desc: "scanQRCode desc",
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: async res => {
          const getCode = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          // this.str = JSON.stringify(res)
          if (res.errMsg != "scanQRCode:ok" && !getCode) {
            return this.$toast.fail("扫码失败");
          }
          try {
            if (getCode) {
              const data = await writeOffsm(getCode);
              if (data.code == 200) {
                this.$emit('veriSuccess')
              } else {
                this.$toast.fail(data.msg || "核销失败");
              }
            }
          } catch (error) {
            console.log(error);
          }
        },
        error: function(error) {
          console.log(error);
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.serve-cancel-btn {
  width: 100%;
}
</style>