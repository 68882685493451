/* 门票类型 */
export const voteTypeList = [
  {
    label: '全部',
    value: '-1'
  },
  {
    label: '成人',
    value: '0'
  },
  {
    label: '儿童',
    value: '1'
  },
  {
    label: '特价',
    value: '2'
  }
]

/* 兑物券核销状态 */
export const verificStatus = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '未核销',
    value: '0'
  },
  {
    label: '已核销',
    value: '1'
  },
  {
    label: '已失效',
    value: '2'
  }
]

/* 服务、住宿类订单状态 */
export const serveOrder = {
  orderStatus: [
    {
      label: '全部',
      value: '-1'
    },
    {
      label: '待付款',
      value: '0'
    },
    {
      label: '待接单',
      value: '1'
    },
    {
      label: '待核销',
      value: '2'
    },
    {
      label: '已使用',
      value: '3'
    },
    {
      label: '退款/售后',
      value: '8'
    },
    {
      label: '已关闭',
      value: '4'
    }
  ],
  afterStatus: [
    {
      value: '1',
      label: '售后中'
    },
    {
      value: '2',
      label: '售后完成'
    }
  ],
  refundStatus: [
    {
      value: '1',
      label: '退款中'
    },
    {
      value: '2',
      label: '退款完成'
    }
  ]
}

/* 门票订单状态 */
export const mpServeOrder = {
  orderStatus: [
    {
      label: '全部',
      value: '-1'
    },
    {
      label: '待付款',
      value: '0'
    },
    {
      label: '待核销',
      value: '2'
    },
    {
      label: '已使用',
      value: '3'
    },
    {
      label: '退款/售后',
      value: '8'
    },
    {
      label: '已关闭',
      value: '4'
    },
    {
      label: '已过期',
      value: '9'
    }
  ],
  afterStatus: [
    {
      value: '1',
      label: '售后中'
    },
    {
      value: '2',
      label: '售后完成'
    }
  ],
  refundStatus: [
    {
      value: '1',
      label: '退款中'
    },
    {
      value: '2',
      label: '退款完成'
    }
  ]
}

/* 购物类订单状态 */
export const shopOrder = {
  orderStatus: [
    {
      label: '全部',
      value: '-1'
    },
    {
      label: '待付款',
      value: '0'
    },
    {
      label: '待接单',
      value: '1'
    },
    {
      label: '待发货',
      value: '2'
    },
    {
      label: '待核销',
      value: '10'
    },
    {
      label: '待收货',
      value: '3'
    },
    {
      label: '已完成',
      value: '4'
    },
    {
      label: '退款/售后',
      value: '8'
    },
    {
      label: '已关闭',
      value: '5'
    }
  ],
  afterStatus: [
    {
      value: '0',
      label: '待处理'
    },
    {
      value: '1',
      label: '售后中'
    },
    {
      value: '2',
      label: '售后完成'
    }
  ],
  refundStatus: [
    {
      value: '1',
      label: '退款中'
    },
    {
      value: '2',
      label: '退款完成'
    }
  ]
}

/* 商户返现结算 */
export const cashBackStatus = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '待打款',
    value: '0'
  },
  {
    label: '已打款',
    value: '1'
  }
]

/* 十二生肖 */
export const zodiacList = ['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']