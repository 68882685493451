<script>
import serveCancelBtn from '@/compontents/serve-cancel-btn'
import { getShopH5, getDiscount, getHxhc, getSaoma, lmReturnCount, getUserInfo, getVeriList } from "@/api/home";
import { mapMutations } from 'vuex'
import Cookies from "js-cookie"
import { zodiacList } from "@/utils/dataStorage"
var that = this
export default {
  name: "Home",
  components: {
    serveCancelBtn
  },
  data () {
    return {
      lmReturnObj: {},//商户联盟返现
      homeInfo: {
        szxcShopData: {},//店铺信息
        browseGoodCountsToday: 0, //今日商品浏览量
        browseGoodCountsYesterday: 0,// 昨日商品浏览量
        browseShopCountsToday: 0, //今日店铺浏览量
        browseShopCountsYesterday: 0,// 昨日店铺浏览量
        businessToday: {},// 今日交易信息
        businessYesterday: {}, //昨日交易信息
        szxcOrderServiceStatistics: {}, // 服务订单信息
        szxcOrderShopStatistics: {},//购物订单信息
        szxcOrderHomeStatistics: {},//民宿订单信息
        szxcOrderTicketStatistics: {},//门票订单信息
      },
      wxConfig: {},// 调用微信的信息
      discount: {},//订单优惠
      isShowShop: true,//是否有商户
      isAdmin: Cookies.get('isHxhc') == 1,//是否是联盟管理员
      smList: [],//扫码数组
      orderList: [
        {
          title: '购物订单',
          pushAdd: 'shoporder',
          parentKey: 'szxcOrderShopStatistics',
          statuList: [
            {
              title: '待付款',
              icon: 'gem',
              active: '1',
              key: 'pendingShopPaymentCounts'
            },
            {
              title: '待接单',
              icon: 'bell',
              active: '2',
              key: 'pendingShopOrderCounts'
            },
            {
              title: '待发货',
              icon: 'send-gift',
              active: '3',
              key: 'pendingShopShipCounts'
            },
            {
              title: '提货待核销',
              icon: 'todo-list',
              active: '4',
              key: 'tiHuoCounts'
            },
            {
              title: '待收货',
              icon: 'gift-card',
              active: '5',
              key: 'pendingShopReceivedCounts'
            },
            {
              title: '退款/售后',
              icon: 'share',
              active: '7',
              key: 'pendingShopRefundAfterSaleCounts'
            }
          ]
        },
        {
          title: '服务订单',
          type: 1,
          pushAdd: 'serveorder',
          parentKey: 'szxcOrderServiceStatistics',
          statuList: [
            {
              title: '待付款',
              icon: 'gem',
              active: '0',
              key: 'pendingServicePaymentCounts'
            },
            {
              title: '待接单',
              icon: 'bell',
              active: '1',
              key: 'pendingServiceShipCounts'
            },
            {
              title: '待核销',
              icon: 'graphic',
              active: '2',
              key: 'pendingServiceReceivedCounts'
            },
            {
              title: '退款/售后',
              icon: 'share',
              active: '8',
              key: 'pendingServiceRefundCounts'
            },
            {
              title: '已使用',
              icon: 'smile',
              active: '3',
              key: 'pendingShopReceivedCounts'
            }
          ]
        },
        {
          title: '住宿订单',
          type: 3,
          pushAdd: 'serveorder',
          parentKey: 'szxcOrderHomeStatistics',
          statuList: [
            {
              title: '待付款',
              icon: 'gem',
              active: '0',
              key: 'pendingHomePaymentCounts'
            },
            {
              title: '待接单',
              icon: 'bell',
              active: '1',
              key: 'pendingHomeShipCounts'
            },
            {
              title: '待核销',
              icon: 'graphic',
              active: '2',
              key: 'pendingHomeReceivedCounts'
            },
            {
              title: '退款/售后',
              icon: 'share',
              active: '8',
              key: 'pendingHomeRefundCounts'
            },
            {
              title: '已关闭',
              icon: 'underway',
              active: '4',
              key: 'pendingHomeCloseCounts'
            }
          ]
        },
        {
          title: '购票订单',
          type: 4,
          pushAdd: 'serveorder',
          parentKey: 'szxcOrderTicketStatistics',
          statuList: [
            {
              title: '待付款',
              icon: 'gem',
              active: '0',
              key: 'ticketPaymentCounts'
            },
            {
              title: '待核销',
              icon: 'graphic',
              active: '2',
              key: 'ticketReceivedCounts'
            },
            {
              title: '已使用',
              icon: 'bell',
              active: '3',
              key: 'a'
            },
            {
              title: '已过期',
              icon: 'underway',
              active: '9',
              key: 'a'
            },
            {
              title: '退款/售后',
              icon: 'share',
              active: '8',
              key: 'a'
            },
          ]
        }
      ],
      dpzj: {
        title: '店铺资金',
        type: 'dp',
        pushAdd: '/withdrawdeposit',
        qtx: 'shopFund',
        txz: 'withDrawnWaiting',
        ytx: 'withDrawn'
      },
      lmfx: {
        title: '联盟返现',
        type: 'lm',
        pushAdd: '/withdrawdeposit',
        qtx: 'lmReturnPrice',
        txz: 'withDrawnWaiting',
        ytx: 'withDrawn'
      },
      mpjd: {
        title: '景点门票',
        type: 'mp',
        pushAdd: '/withdrawdeposit',
        qtx: 'shopFund',
        txz: 'withDrawnWaiting',
        ytx: 'withDrawn'
      },
      loadingVeri: false,
      finishedVeri: false,
      veriList: [],
      userInfo: {},
      getListParams: {
        pageNo: 1,
        pageSize: 10
      },
    };
  },
  mounted () {
    this.getUserInfo()
    this.$refs.mylist.check()
  },
  filters: {
    bigNum: function (value) {
      if (value > 100) {
        return '99+'
      } else if (value == 0) {
        return ''
      } else {
        return value
      }
    }
  },
  methods: {
    ...mapMutations(['setShopInfo','setLmReturnObj']),
    async getUserInfo() {
      let res = await getUserInfo()
      if(res.result?.code == 200) {
        this.userInfo = res.result.data
      }
    },
    async getVeriList () {
      try {
        this.loadingVeri = true
        const result = await getVeriList(this.getListParams)
        let res = result.result
        this.loadingVeri = false
        if(res.code == 200) {
          const orderPageVOList = res.data.list
          if(this.getListParams.pageNo == 1) {
            this.veriList.splice(0,this.veriList.length)
          }
          this.veriList = [...this.veriList, ...res.data.list];
          this.getListParams.pageNo = res.data.pageNo + 1;
          if (
            res.data.count == this.veriList.length ||
            res.data.list.length == 0
          ) {
            this.finishedVeri = true;
          }
        }
      } catch (error) {
        this.loadingVeri = false
        this.finishedVeri = true;
      }
    },
    getBadge ({ parentKey, key }) {
      let value = this.homeInfo[parentKey][key]
      if (value >= 100) {
        return '99+'
      } else if (value == 0) {
        return ''
      } else {
        return value
      }
    },
    goJump (url) {
      if (Cookies.get('isHxhc') == 1) {
        window.location.href = 'https://lnyj.ztesa.com.cn/index?id=hc'
      } else {
        window.location.href = 'https://lnyj.ztesa.com.cn/index?id=sh' + this.homeInfo.shopId
      }
    },
    goOrderPrefer (type) {
      this.$router.push({ path: "/OrderPrefer", query: { type: type } })
    },
    async getShop () {
      try {
        const res = await getShopH5()
        if (res.code == 200) {
          this.setShopInfo(res.data)
          this.homeInfo = res.data
        } else if (res.result == 'login' || res.code == 401) {
          Cookies.remove('token')
          this.$router.replace("/login")
        } else if (res.code == 400) {
          this.$toast.fail(res.msg)
        } else if (res.code == 405) {
          this.isShowShop = false
        }
      } catch (error) {
      }
    },
    async getDiscount () {
      const res = await getDiscount()
      if (res.code == 200) {
        this.discount = Object.assign({}, res.data)
      } else if (res.code == 405) {
        this.isShowShop = false
      }
    },
    async getHxhc () {
      const res = await getHxhc()
      if (res.code == 200) {
        this.homeInfo = res.data
        this.setShopInfo(res.data)
      }
    },
    async lmReturnCount() {
      const res = await lmReturnCount()
      if (res.code == 200) {
        this.lmReturnObj = res.data
        this.setLmReturnObj(res.data)
      }
    },
    async getSaoma () {
      const res = await getSaoma()
      if (res.code == 200) {
        this.smList = res.data
      }
    },
    // 账号信息展示
    renderHeader () {
      return (
        <div class="home-header">
          <div class="header-content">
            <div class="content-left">
              <van-image
                width="60px"
                height="60px"
                fit="cover"
                round
                src={this.userInfo?.shopLogo}
              />
            </div>
            <div class="content-right">
              <div class="shop-name">{this.homeInfo.shopName}</div>
              {Cookies.get('isHxhc') == 1 && <div class="shop-name">梁弄景点管理员</div>}
              <div>
                <span>账号：{this.userInfo?.loginCode}</span>
              </div>
              <div>
                <span>{`开${'店'}时间：` + this.userInfo?.createDate}</span>
              </div>
            </div>
          </div>
        </div>
      )
    },
    //核销列表
    renderVerification () {
      return (
        <div>
          <div class="van-hairline--bottom hxjl">核销记录</div>
          <van-list
            ref="mylist"
            v-model={this.loadingVeri}
            finished={this.finishedVeri}
            finished-text="没有更多了"
            onLoad={()=>{this.getVeriList()}}
            class="mylist"
          >
            {this.veriList.map(e => {
              return <van-cell >
                      <div class="veri-list" slot="title">
                        <div>
                          <span class="van-ellipsis nickname">{`${e.nickname}`}</span>
                          <span style="float: right;margin-left: 5px">{` X${e.number}`}</span>
                          <span class="van-ellipsis num">{` ${e.goodsName}`}</span>
                        </div>
                        <span class="hxState">{`${e.hxState == 1 ? '核销失败' : '已核销'}`}</span>
                      </div>
                      <div slot="label" class="veri-label">
                        <span>{e.createDate}</span>
                        <span>{e.phone}</span>
                      </div>
                     </van-cell>
            })}
          </van-list>
        </div>
      )
    },
    //今日经营
    renderManage () {
      return (
        <div class="today-manage">
          <div class="manage-title">今日经营</div>
          <van-grid column-num={2}>
            <van-grid-item>
              <div class="count">
                {this.homeInfo.businessToday.paymentAmountToday || 0}
              </div>
              <div class="count-name">支付金额（元）</div>
              <div class="pre-count">
                <span>昨日：</span>
                <span>{
                  this.homeInfo.businessYesterday.paymentAmountYesterday || 0
                }</span>
              </div>
            </van-grid-item>
            <van-grid-item>
              <div class="count">
                {this.homeInfo.businessToday.paymentOrderCountsToday || 0}
              </div>
              <div class="count-name">支付订单数</div>
              <div class="pre-count">
                <span>昨日：</span>
                <span>{
                  this.homeInfo.businessYesterday.paymentOrderCountsYesterday || 0
                }</span>
              </div>
            </van-grid-item>
            <van-grid-item>
              <div class="count">{this.homeInfo.browseShopCountsToday || 0}</div>
              <div class="count-name">{Cookies.get('isHxhc') == 1 ? '景点浏览量' : '店铺浏览量'}</div>
              <div class="pre-count">
                <span>昨日：</span>
                <span>{this.homeInfo.browseShopCountsYesterday || 0}</span>
              </div>
            </van-grid-item>
            <van-grid-item>
              <div class="count">{this.homeInfo.cumulativeCustomer || 0}</div>
              <div class="count-name">{Cookies.get('isHxhc') == 1 ? '购票客户数' : '累计客户数'}</div>
            </van-grid-item>
          </van-grid>
        </div>
      )
    },
    //今日扫码数
    renderScanCode () {
      return (
        <div class="card">
          <div class="card-title">今日扫码数</div>
          <van-grid column-num={6} class="zodiac-grid" gutter={10}>
            {zodiacList.map((item, i) => {
              return (
                <van-grid-item>
                  <div class='zodiac'>
                    <img src={require(`../../assets/img/zodiac/icon${i + 1}.png`)} />
                    <span>{this.smList[i] ? this.smList[i].counts : 0}</span>
                  </div>
                </van-grid-item>
              )
            })}
          </van-grid>
        </div>
      )
    },
    //店铺资金---联盟返现
    renderMoney (item) {
      let homeInfo = item.title == '联盟返现' ? this.lmReturnObj : this.homeInfo
      return (
        <div class="my-money">
          <van-grid column-num={3}>
            <van-grid-item>
              <div class="money-title">{`${item.title}（元）`}</div>
              <div class="money-count">
                {homeInfo[item.qtx] || 0}
                <span class="go-money" onClick={() => { this.$router.push({
                  path:item.pushAdd,
                  query: {
                    type: item.type
                  }
                }) }}
                >去提现</span>
              </div>
            </van-grid-item>
            <van-grid-item>
              <div class="money-title">提现中（元）</div>
              <div class="money-count">{homeInfo[item.txz] || 0}</div>
            </van-grid-item>
            <van-grid-item>
              <div class="money-title">已提现（元）</div>
              <div class="money-count">{homeInfo[item.ytx] || 0}</div>
            </van-grid-item>
          </van-grid>
        </div>
      )
    },
    //我的商品
    renderMyShop () {
      return (
        <div class="box">
          <div class="box-title">我的商品</div>
          <van-grid column-num={3}>
            <van-grid-item>
              <div class="count">
                {this.homeInfo.businessToday.transactionCountToday || 0}
              </div>
              <div class="count-name">商品交易份数</div>
              <div class="pre-count">
                <span>昨日：</span>
                <span>{
                  this.homeInfo.businessYesterday.transactionCountYesterday || 0
                }</span>
              </div>
            </van-grid-item>
            <van-grid-item>
              <div class="count">{this.homeInfo.browseGoodCountsToday || 0}</div>
              <div class="count-name">商品浏览量</div>
              <div class="pre-count">
                <span>昨日：</span>
                <span>{this.homeInfo.browseGoodCountsYesterday || 0}</span>
              </div>
            </van-grid-item>
            <van-grid-item>
              <div class="count">{this.homeInfo.goodSharingCountsToday || 0}</div>
              <div class="count-name">商品分享数</div>
              <div class="pre-count">
                <span>昨日：</span>
                <span>{this.homeInfo.goodSharingCountsYesterday || 0}</span>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      )
    },
    //订单优惠
    renderOrderPrefer () {
      return (
        <div class="box">
          <div class="box-title">订单优惠</div>
          <van-grid column-num={3}>
            <van-grid-item onClick={() => { this.goOrderPrefer(1) }}>
              <div class="count">
                {this.discount.totalDiscount || 0}
              </div>
              <div class="count-name">优惠总额</div>
            </van-grid-item>
            <van-grid-item onClick={() => { this.goOrderPrefer(2) }}>
              <div class="count">{this.discount.todayDiscount || 0}</div>
              <div class="count-name">今日优惠金额</div>
              <div class="pre-count">
                <span>昨日：</span>
                <span>{this.discount.yesDiscount || 0}</span>
              </div>
            </van-grid-item>
            <van-grid-item onClick={() => { this.goOrderPrefer(3) }}>
              <div class="count">{this.discount.refundDiscount || 0}</div>
              <div class="count-name">回退优惠总额</div>
            </van-grid-item>
            <van-grid-item onClick={() => this.goOrderPrefer(1)}>
              <div class="count">
                {this.discount.totalCount || 0}
              </div>
              <div class="count-name">优惠订单总数</div>
            </van-grid-item>
            <van-grid-item onClick={() => this.goOrderPrefer(2)}>
              <div class="count">{this.discount.todayCount || 0}</div>
              <div class="count-name">今日优惠订单</div>
              <div class="pre-count">
                <span>昨日：</span>
                <span>{this.discount.yesCount || 0}</span>
              </div>
            </van-grid-item>
            <van-grid-item onClick={() => this.goOrderPrefer(3)}>
              <div class="count">{this.discount.refundCount || 0}</div>
              <div class="count-name">回退优惠订单</div>
            </van-grid-item>
          </van-grid>
        </div>
      )
    },
    //订单列表
    renderOrderList () {
      let list = this.isAdmin ? this.orderList.slice(3, 4) : this.orderList.slice(0, 3)
      return (
        list.map(order => {
          return (
            <div class="box">
              <div class="box-title">
                <span>{order.title}</span>
                <div
                  class="all"
                  onClick={() => this.$router.push({
                    path: `/${order.pushAdd}`,
                    query: {
                      type: order.type,
                    },
                  })}
                >
                  {'全部 >>'}
                </div>
              </div>
              <van-grid column-num={5} clickable>
                {order.statuList.map(item => {
                  return (
                    <van-grid-item
                      icon={item.icon}
                      text={item.title}
                      badge={this.getBadge({ parentKey: order.parentKey, key: item.key })}
                      onClick={() => {
                        this.$router.push({
                          path: `/${order.pushAdd}?active=${item.active}`,
                          query: {
                            type: order.type,
                            active: item.active,
                          },
                        })
                      }}
                    />
                  )
                })}
              </van-grid>
            </div>
          )
        })
      )
    },
    //经营管理
    renderOperatManage () {
      if (this.isAdmin) {
        return (
          <div class="box">
            <div class="box-title">
              <span>经营管理</span>
            </div>
            <van-grid column-num={4} clickable>
              <van-grid-item
                icon="todo-list-o"
                class="hexi"
                text="联盟返现支出"
                onClick={() => this.$router.push({
                  path: '/verificWinList',
                  query: {
                    title: '联盟返现支出',
                    type: 'spend',
                  },
                })}
              />
              <van-grid-item
                icon="bill-o"
                class="hexi"
                text="商户返现结算"
                onClick={() => this.$router.push({
                  path: '/verificWinList',
                  query: {
                    title: '商户返现结算',
                    type: 'merchantCashBack'
                  },
                })}
              />
            </van-grid>
          </div>
        )
      }
      return (
        <div class="box">
          <div class="box-title">
            <span>经营管理</span>
          </div>
          <van-grid column-num={4} clickable>
            <van-grid-item
              icon="shop-o"
              onClick={() => this.$router.push('/shopsetting')}
              text="店铺配置"
            />
            <van-grid-item
              icon="bar-chart-o"
              text="商品管理"
              onClick={() => this.$router.push('/comymanage')}
            />
            <van-grid-item
              icon="wap-home-o"
              text="房型管理"
              onClick={() => this.$router.push('/roommanage')}
            />
            <van-grid-item
              icon="coupon-o"
              class="hexi"
              text="快捷买单记录"
              onClick={() => this.$router.push('/fastPay')}
            />
            <van-grid-item
              icon="todo-list-o"
              class="hexi"
              text="兑物券核销"
              onClick={() => this.$router.push({
                path: '/verificWinList',
                query: {
                  title: '兑物券核销',
                  type: 'verific'
                },
              })}
            />
            <van-grid-item
              icon="bill-o"
              class="hexi"
              text="联盟返现记录"
              onClick={() => this.$router.push({
                path: '/verificWinList',
                query: {
                  title: '联盟返现记录',
                  type: 'cashBack',
                },
              })}
            />
          </van-grid>
        </div>
      )
    },
    onVeriSuccess() {
      this.$toast.success("核销成功！");
      this.finishedVeri = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loadingVeri = true;
      this.$set(this.getListParams, 'pageNo', 1)
      this.getVeriList();
    }
  },
  render () {
    return (
      <div class="home">
        <van-nav-bar title="甬农鲜扫码核销" fixed>
          <template slot="right">
            <serve-cancel-btn onVeriSuccess={this.onVeriSuccess} shopId={this.homeInfo.shopId}>
              <van-icon name="enlarge" class="saoma" />
            </serve-cancel-btn>
          </template>
        </van-nav-bar>
        {this.renderHeader()}
        {this.renderVerification()}
      </div>
    )

  }
};
</script>


<style scoped lang="less">
.home {
  padding-top: 46px;
  background-color: #fff !important;
  height: calc(100vh - 46px);
  .card {
    .card-title {
      font-size: 18px;
      background-color: #fff;
      padding: 10px;
    }
  }
  .van-nav-bar {
    z-index: 999;
    .saoma {
      font-size: 20px;
    }
  }
  .home-header {
    height: 120px;
    background-color: #ccc;
    position: relative;
    .header-right {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      background-color: rgb(236, 74, 33);
      border-radius: 12px 0 0 12px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      color: #fff;
      padding: 0 10px;
    }
    .header-content {
      display: flex;
      height: 100%;
      align-items: center;
      padding-left: 10px;
      .content-right {
        margin-left: 10px;
        line-height: 18px;
        font-size: 12px;
        .shop-name {
          font-size: 14px;
        }
      }
      .content-shopSetting {
        font-style: normal;
        font-size: 13px;
        color: #ffffff;
        width: 92px;
        height: 28px;
        line-height: 28px;
        background: inherit;
        background-color: rgba(249, 111, 22, 1);
        border-radius: 70px;
        border-right: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        position: absolute;
        right: 0;
        text-align: center;
      }
    }
  }
  .zodiac-grid {
    &/deep/.van-grid-item__content {
      padding: 0;
    }
  }
  .zodiac {
    text-align: center;
    img {
      width: 100%;
    }
    span {
      font-size: 12px;
    }
  }
  .box {
    margin-top: 10px;
    .box-title {
      font-size: 18px;
      background-color: #fff;
      padding: 10px;
      .all {
        float: right;
        font-size: 12px;
        color: #999;
      }
    }
    .order {
      width: 100%;
      // display: flex;
      // flex-wrap: wrap;
      overflow-x: auto;
      font-size: 12px;
      background-color: #fff;
      border-top: 1px solid #ebedf0;
      div {
        width: 20%;
        display: inline-block;
        height: 86px;
        position: relative;
        box-sizing: border-box;
        border: 0 solid #ebedf0;
        border-width: 0 1px 1px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        span {
          margin-top: 12px;
        }
      }
    }
    .van-grid {
      background-color: #fff;
      .count {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
      }
      .count-name {
        font-size: 12px;
        line-height: 18px;
      }
      .pre-count {
        font-size: 12px;
        line-height: 18px;
        color: #999;
      }
    }
  }
  .today-manage {
    margin-top: 10px;
    .manage-title {
      font-size: 18px;
      background-color: #fff;
      padding: 10px;
    }
    .van-grid {
      .count {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
      }
      .count-name {
        font-size: 12px;
        line-height: 18px;
      }
      .pre-count {
        font-size: 12px;
        line-height: 18px;
        color: #999;
      }
    }
  }
  .my-money {
    margin-top: 10px;
    .money-title {
      font-size: 14px;
      color: #999;
      line-height: 24px;
    }
    .money-count {
      font-size: 20px;
      line-height: 28px;
      .go-money {
        color: rgb(8, 140, 228);
        font-size: 14px;
      }
    }
  }
}
.hexi {
  &/deep/ .van-grid-item__content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
.server-class {
  width: 120%;
  display: flex;
  flex-wrap: nowrap;
}
.hxjl {
  font-size: 18px;
  padding-left: 15px;
  padding-bottom: 10px;
  margin-top: 20px;
}
::v-deep {
  .mylist {
    height: 440px;
    overflow: auto;
  }
}
.veri-list {
  display: flex;
  justify-content: space-between;
  .nickname {
    display: inline-block;
    width: 140px;
  }
  .num {
    float: right;
    // display: inline-block;
    max-width: 120px;
  }
  .hxState {
    color: #969799;
  }
}
.veri-label {
  display: flex;
  justify-content: space-between;
}
</style>
